
import { defineComponent } from "vue";
import { enUS, zhCN } from "@/plugins/i18n";
import Hero from "@/components/Hero/index.vue";
import VideoButton from "@/components/VideoButton/index.vue";

export default defineComponent({
  name: "TheHero",

  components: {
    Hero,
    VideoButton,
  },

  i18n: {
    messages: {
      [zhCN]: {
        title: "玉串自动化",
        video: "观看视频",
        content: "集全球前沿技术，成就智慧物流自动化系统！",
      },
      [enUS]: {
        title: "Yuchuan automation technology",
        video: "Video",
        content: `
          Yuchuan automation integrate globle leading technology to make intelligent logistics system.
        `,
      }
    }
  }
});


import { defineComponent } from "vue";

export default defineComponent({
  name: "VSection",

  props: {
    title: {
      type: String,
      default: ""
    },

    background: {
      type: String,
      default: "white"
    }
  },
});
